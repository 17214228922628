<template>
    <div class="bg-uf-lime py-4 md:py-2">
        <div class="container px-4 md:px-0 flex justify-between items-center text-xs font-roboto">
            <div class="space-x-6 flex justify-start">
                <a href="tel:+4972557657995" class="flex">
                    <svg class="h-4 w-4"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                         stroke="currentColor"
                         aria-hidden="true"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 5a2 2 0 012-2h3.28a1 1 0 01.948.684l1.498 4.493a1 1 0 01-.502 1.21l-2.257 1.13a11.042 11.042 0 005.516 5.516l1.13-2.257a1 1 0 011.21-.502l4.493 1.498a1 1 0 01.684.949V19a2 2 0 01-2 2h-1C9.716 21 3 14.284 3 6V5z" />
                    </svg>

                    <span class="pl-2">+49 7255 76 57 995</span>
                </a>

                <a href="mailto:hallo@unitfactory.de" class="flex">
                    <svg class="h-4 w-4"
                         xmlns="http://www.w3.org/2000/svg"
                         fill="none"
                         viewBox="0 0 24 24"
                         stroke="currentColor"
                         aria-hidden="true"
                    >
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M3 8l7.89 5.26a2 2 0 002.22 0L21 8M5 19h14a2 2 0 002-2V7a2 2 0 00-2-2H5a2 2 0 00-2 2v10a2 2 0 002 2z" />
                    </svg>

                    <span class="pl-2">hallo@unitfactory.de</span>
                </a>
            </div>

            <div class="flex justify-end">
                <a href="https://www.facebook.com/unitfactoryug" target="_blank">
                    <svg class="h-4 w-4"
                         xmlns="http://www.w3.org/2000/svg"
                         viewBox="0 0 310 310"
                         stroke="currentColor"
                         aria-hidden="true"
                    >
                        <path d="M81.703,165.106h33.981V305c0,2.762,2.238,5,5,5h57.616c2.762,0,5-2.238,5-5V165.765h39.064
                            c2.54,0,4.677-1.906,4.967-4.429l5.933-51.502c0.163-1.417-0.286-2.836-1.234-3.899c-0.949-1.064-2.307-1.673-3.732-1.673h-44.996
                            V71.978c0-9.732,5.24-14.667,15.576-14.667c1.473,0,29.42,0,29.42,0c2.762,0,5-2.239,5-5V5.037c0-2.762-2.238-5-5-5h-40.545
                            C187.467,0.023,186.832,0,185.896,0c-7.035,0-31.488,1.381-50.804,19.151c-21.402,19.692-18.427,43.27-17.716,47.358v37.752H81.703
                            c-2.762,0-5,2.238-5,5v50.844C76.703,162.867,78.941,165.106,81.703,165.106z"/>
                    </svg>
                </a>
            </div>
        </div>
    </div>
</template>

<script>
export default {
    name: "Headline"
}
</script>
