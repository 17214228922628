<template>
    <section class="mb-10">
        <div class="container flex items-start flex-col font-roboto text-uf-blue">
            <h3 class="font-thin uppercase text-4xl flex place-self-center">Datenschutzerklärung</h3>

            <div class="flex flex-col mt-20 space-y-2">
                <p>Datenschutz</p>

                <p class="font-light">
                    Nachfolgend möchten wir Sie über unsere Datenschutzerklärung informieren. Sie finden hier Informationen über die Erhebung und Verwendung persönlicher Daten bei der Nutzung unserer Webseite. Wir beachten dabei das für Deutschland geltende Datenschutzrecht. Sie können diese Erklärung jederzeit auf unserer Webseite abrufen.
                </p>

                <p class="font-light">
                    Wir weisen ausdrücklich darauf hin, dass die Datenübertragung im Internet (z.B. bei der Kommunikation per E-Mail) Sicherheitslücken aufweisen und nicht lückenlos vor dem Zugriff durch Dritte geschützt werden kann.
                </p>

                <p class="font-light">
                    Die Verwendung der Kontaktdaten unseres Impressums zur gewerblichen Werbung ist ausdrücklich nicht erwünscht, es sei denn wir hatten zuvor unsere schriftliche Einwilligung erteilt oder es besteht bereits eine Geschäftsbeziehung. Der Anbieter und alle auf dieser Website genannten Personen widersprechen hiermit jeder kommerziellen Verwendung und Weitergabe ihrer Daten.
                </p>
            </div>

            <div class="flex flex-col mt-6 space-y-2">
                <p>Personenbezogene Daten</p>

                <p class="font-light">
                    Sie können unsere Webseite ohne Angabe personenbezogener Daten besuchen. Soweit auf unseren Seiten personenbezogene Daten (wie Name, Anschrift oder E-Mail Adresse) erhoben werden, erfolgt dies, soweit möglich, auf freiwilliger Basis. Diese Daten werden ohne Ihre ausdrückliche Zustimmung nicht an Dritte weitergegeben. Sofern zwischen Ihnen und uns ein Vertragsverhältnis begründet, inhaltlich ausgestaltet oder geändert werden soll oder Sie an uns eine Anfrage stellen, erheben und verwenden wir personenbezogene Daten von Ihnen, soweit dies zu diesen Zwecken erforderlich ist (Bestandsdaten). Wir erheben, verarbeiten und nutzen personenbezogene Daten soweit dies erforderlich ist, um Ihnen die Inanspruchnahme des Webangebots zu ermöglichen (Nutzungsdaten). Sämtliche personenbezogenen Daten werden nur solange gespeichert wie dies für den genannten Zweck (Bearbeitung Ihrer Anfrage oder Abwicklung eines Vertrags) erforderlich ist. Hierbei werden steuer- und handelsrechtliche Aufbewahrungsfristen berücksichtigt. Auf Anordnung der zuständigen Stellen dürfen wir im Einzelfall Auskunft über diese Daten (Bestandsdaten) erteilen, soweit dies für Zwecke der Strafverfolgung, zur Gefahrenabwehr, zur Erfüllung der gesetzlichen Aufgaben der Verfassungsschutzbehörden oder des Militärischen Abschirmdienstes oder zur Durchsetzung der Rechte am geistigen Eigentum erforderlich ist.
                </p>
            </div>

            <div class="flex flex-col mt-6 space-y-2">
                <p>Kommentarfunktionen</p>

                <p class="font-light">
                    Wir erheben personenbezogene Daten im Rahmen der Veröffentlichung eines Inhalts oder einer Kommentierung zu einem Artikel oder Beitrag nur in dem Umfang, wie sie von Ihnen mitgeteilt werden. Bei der Veröffentlichung eines Kommentars wird die von Ihnen angegebene Email-Adresse gespeichert, aber nicht veröffentlicht. Ihr Name wird veröffentlicht, wenn Sie nicht unter Pseudonym geschrieben haben.
                </p>
            </div>

            <div class="flex flex-col mt-6 space-y-2">
                <p>Datenschutzerklärung für die Newsletterfunktion</p>

                <p class="font-light">
                    Durch die freiwillige Eintragung Ihrer E-Mail-Adresse in unseren Newsletter und Bestätigung dieser per Double-Opt-In-Verfahren erklären Sie Ihre Einwilligung, dass Sie unseren E-Mail-Newsletter erhalten möchten. Sie können dieser Verwendung Ihrer E-Mail-Adresse jederzeit widersprechen, ohne dass hierfür andere als die Übermittlungskosten nach den Basistarifen entstehen. Sie können sich jederzeit aus dem Newsletter abmelden. Jede E-Mail enthält einen „Abmelden“-Link. Für den Empfang des Newsletters ist die Angabe einer E-Mail-Adresse ausreichend. Zusätzliche freiwillige Angaben dienen lediglich der Personalisierung des Newsletters.
                </p>
            </div>

            <div class="flex flex-col mt-6 space-y-2">
                <p>Datenschutzerklärung für das Facebook-Plugin („Gefällt mir“)</p>

                <p class="font-light">
                    Diese Webseite nutzt Plugins des Anbieters Facebook.com, welche durch das Unternehmen Facebook Inc., 1601 S. California Avenue, Palo Alto, CA 94304 in den USA bereitgestellt werden. Nutzer unserer Webseite, auf der das Facebook-Plugin („Gefällt mir“-Button) installiert ist, werden hiermit darauf hingewiesen, dass durch das Plugin eine Verbindung zu Facebook aufgebaut wird, wodurch eine Übermittlung an Ihren Browser durchgeführt wird, damit das Plugin auf der Webseite erscheint.
                </p>

                <p class="font-light">
                    Des Weiteren werden durch die Nutzung Daten an die Facebook-Server weitergeleitet, welche Informationen über Ihre Webseitenbesuche auf unserer Homepage enthalten. Dies hat für eingeloggte Facebook-Nutzer zur Folge, dass die Nutzungsdaten Ihrem persönlichen Facebook-Account zugeordnet werden.
                </p>

                <p class="font-light">
                    Sobald Sie als eingeloggter Facebook-Nutzer aktiv das Facebook-Plugin nutzen (z.B. durch das Klicken auf den „Gefällt mir“ Knopf oder die Nutzung der Kommentarfunktion), werden diese Daten zu Ihrem Facebook-Account übertragen und veröffentlicht. Dies können Sie nur durch vorheriges Ausloggen aus Ihrem Facebook-Account umgehen.
                </p>

                <p class="font-light">
                    Weitere Information bezüglich der Datennutzung durch Facebook entnehmen Sie bitte den datenschutzrechtlichen Bestimmungen auf Facebook unter http://de-de.facebook.com/policy.php.
                </p>
            </div>

            <div class="flex flex-col mt-6 space-y-2">
                <p>Auskunftsrecht</p>

                <p class="font-light">
                    Sie haben das jederzeitige Recht, sich unentgeltlich und unverzüglich über die zu Ihrer Person erhobenen Daten zu erkundigen. Sie haben das jederzeitige Recht, Ihre Zustimmung zur Verwendung Ihrer angegeben persönlichen Daten mit Wirkung für die Zukunft zu widerrufen. Zur Auskunftserteilung wenden Sie sich bitte an den Anbieter unter den Kontaktdaten im Impressum.
                </p>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Privacy'
}
</script>
