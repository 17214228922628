<template>
    <div class="bg-white py-4 md:py-10 sticky top-0">
        <div class="container px-4 md:px-0 flex justify-between items-center text-xs font-roboto">
            <div class="flex justify-start lg:w-0 lg:flex-1">
                <a href="/">
                    <span class="sr-only">UnitFactory</span>

                    <img class="h-8 w-auto sm:h-10" src="@/assets/images/logo.png" alt="UnitFactory">
                </a>
            </div>

            <div class="md:hidden">
                <button type="button"
                        class="bg-white p-2 inline-flex items-center justify-center text-uf-blue focus:outline-none "
                        aria-expanded="false"
                        @click="isMobileMenuOpen = !isMobileMenuOpen"
                >
                    <span class="sr-only">Menü öffnen</span>

                    <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                        <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M4 6h16M4 12h16M4 18h16" />
                    </svg>
                </button>
            </div>

            <nav class="hidden md:flex space-x-10">
                <a href="#about"
                   class="font-roboto text-lg uppercase transition duration-500 ease-in-out text-uf-turquoise transform hover:text-uf-blue"
                   v-smooth-scroll
                >
                    <span>Über uns</span>
                </a>

                <a href="#team"
                   class="font-roboto text-lg uppercase transition duration-500 ease-in-out text-uf-turquoise transform hover:text-uf-blue"
                   v-smooth-scroll
                >
                    <span>Team</span>
                </a>

                <a href="#projects"
                   class="font-roboto text-lg uppercase transition duration-500 ease-in-out text-uf-turquoise transform hover:text-uf-blue"
                   v-smooth-scroll
                >
                    <span>Projekte</span>
                </a>

                <a href="mailto:hallo@unitfactory.de"
                   class="font-roboto text-lg uppercase transition duration-500 ease-in-out text-uf-turquoise transform hover:text-uf-blue"
                >
                    <span>Kontakt</span>
                </a>
            </nav>

            <transition
                enter-active-class="duration-200 ease-out"
                enter-class="opacity-0 scale-95"
                enter-to-class="opacity-100 scale-100"
                leave-active-class="duration-100 ease-in"
                leave-class="opacity-100 scale-100"
                leave-to-class="opacity-0 scale-95"
            >
                <div class="absolute top-0 inset-x-0 p-2 transition transform origin-top-right md:hidden"
                     v-show="isMobileMenuOpen"
                >
                    <div class="rounded-lg h-screen shadow-lg ring-1 ring-black ring-opacity-5 bg-white divide-y-2 divide-gray-50">
                        <div class="pt-5 pb-6 px-5">
                            <div class="flex items-center justify-between">
                                <div>
                                    <img class="h-8 w-auto" src="@/assets/images/logo.png" alt="UnitFactory">
                                </div>

                                <div class="-mr-2">
                                    <button type="button"
                                            class="bg-white rounded-md p-2 inline-flex items-center justify-center text-gray-400 hover:text-gray-500 hover:bg-gray-100 focus:outline-none focus:ring-2 focus:ring-inset focus:ring-indigo-500"
                                            @click="isMobileMenuOpen = !isMobileMenuOpen"
                                    >
                                        <span class="sr-only">Menü schließen</span>

                                        <svg class="h-6 w-6" xmlns="http://www.w3.org/2000/svg" fill="none" viewBox="0 0 24 24" stroke="currentColor" aria-hidden="true">
                                            <path stroke-linecap="round" stroke-linejoin="round" stroke-width="2" d="M6 18L18 6M6 6l12 12" />
                                        </svg>
                                    </button>
                                </div>
                            </div>

                            <div class="mt-6">
                                <nav class="grid grid-cols-1 gap-7">
                                    <a href="#about"
                                       class="font-roboto text-lg uppercase transition duration-500 ease-in-out text-uf-turquoise transform hover:text-uf-blue"
                                       v-smooth-scroll
                                    >
                                        <span>Über uns</span>
                                    </a>

                                    <a href="#team"
                                       class="font-roboto text-lg uppercase transition duration-500 ease-in-out text-uf-turquoise transform hover:text-uf-blue"
                                       v-smooth-scroll
                                    >
                                        <span>Team</span>
                                    </a>

                                    <a href="#projects"
                                       class="font-roboto text-lg uppercase transition duration-500 ease-in-out text-uf-turquoise transform hover:text-uf-blue"
                                       v-smooth-scroll
                                    >
                                        <span>Projekte</span>
                                    </a>

                                    <a href="mailto:hallo@unitfactory.de"
                                       class="font-roboto text-lg uppercase transition duration-500 ease-in-out text-uf-turquoise transform hover:text-uf-blue"
                                    >
                                        <span>Kontakt</span>
                                    </a>
                                </nav>
                            </div>
                        </div>
                    </div>
                </div>
            </transition>
        </div>
    </div>
</template>

<script>
export default {
    name: 'Navbar',
    data: () => (
        {
            isMobileMenuOpen: false
        }
    )
}
</script>
