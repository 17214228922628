<template>
    <section id="projects" class="h-screen pt-32">
        <div class="container flex items-center flex-col">
            <h1 class="font-thin font-roboto uppercase text-uf-blue text-4xl text-center">Unsere Projekte</h1>

            <ul class="grid gap-6 grid-cols-1 md:grid-cols-2 pt-10">
                <li class="col-span-1 bg-uf-lightgray">
                    <div class="w-full flex flex-col items-center justify-between p-3">
                        <img class="h-24 w-auto" src="@/assets/images/projects/mahlzeit.png" alt="mahlzeit.today">

                        <p class="font-roboto text-uf-blue text-lg">Das Portal für Deinen Mittagstisch</p>
                    </div>
                </li>

                <li class="col-span-1 bg-uf-lightgray">
                    <div class="w-full flex flex-col items-center justify-between p-3">
                        <img class="h-24 w-auto" src="@/assets/images/projects/collectstudio.png" alt="collect.studio">

                        <p class="font-roboto text-uf-blue text-lg">Exklusiver Shop für außergewöhnliche Design-Möbel</p>
                    </div>
                </li>

                <li class="col-span-1 bg-uf-lightgray">
                    <div class="w-full flex flex-col items-center justify-between p-3">
                        <img class="h-24 w-auto" src="@/assets/images/projects/daylaborers.png" alt="daylaborers">

                        <p class="font-roboto text-uf-blue text-lg">Unsere Entwickleragentur</p>
                    </div>
                </li>

                <li class="col-span-1 bg-uf-turquoise">
                    <div class="w-full flex items-center justify-center flex-col p-3 mt-6">
                        <a href="#contact"
                           class="font-roboto text-lg text-white uppercase py-2 px-6 border border-white"
                           v-smooth-scroll
                        >
                            <span>Deine Idee?</span>
                        </a>

                        <p class="font-roboto text-uf-blue text-lg">Werde ein Teil von uns</p>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Projects'
}
</script>
