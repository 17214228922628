<template>
    <section id="team" class="h-screen bg-uf-turquoise pt-32">
        <div class="container flex items-center flex-col">
            <h1 class="font-thin font-roboto uppercase text-white text-4xl text-center">Das Team</h1>

            <p class="font-roboto text-white text-lg px-6 md:w-1/2 pt-10 text-center">
                Wir sind nett – soviel schonmal vorab. Und wir lieben unsere Arbeit. Dazu sind wir natürlich kompetent, erfahren und vor allem eines: begeisterungsfähig für alle digitalen Themen.
            </p>

            <ul class="grid gap-6 grid-cols-1 md:grid-cols-2 pt-10">
                <li class="col-span-1">
                    <div class="w-full flex items-start justify-between sm:pt-3 p-6 space-x-4">
                        <img class="h-24 w-auto flex-shrink-0 -mt-4" src="@/assets/images/people/ralf.svg" alt="UnitFactory">

                        <div class="flex-1">
                            <h3 class="font-thin font-roboto text-uf-blue text-xl uppercase">Ralf Kronen</h3>

                            <p class="font-semibold font-roboto text-uf-blue text-lg uppercase">Mädchen für alles</p>

                            <p class="font-thin font-roboto text-white text-lg">Denkt über den Tellerrand und immer gleich groß. Liebt seine Weihnachtskugeln.</p>
                        </div>
                    </div>
                </li>

                <li class="col-span-1">
                    <div class="w-full flex items-start justify-between p-6 space-x-6">
                        <img class="h-24 w-auto flex-shrink-0 -mt-4" src="@/assets/images/people/marc.svg" alt="UnitFactory">

                        <div class="flex-1">
                            <h3 class="font-thin font-roboto text-uf-blue text-xl uppercase">Marc Neumaier</h3>

                            <p class="font-semibold font-roboto text-uf-blue text-lg ">Laberbacke</p>

                            <p class="font-thin font-roboto text-white text-lg">Lebt und liebt den Vertrieb, und seine Kunden.</p>
                        </div>
                    </div>
                </li>

                <li class="col-span-1">
                    <div class="w-full flex items-start justify-between p-6 space-x-6">
                        <img class="h-24 w-auto flex-shrink-0 -mt-4" src="@/assets/images/people/katja.svg" alt="UnitFactory">

                        <div class="flex-1">
                            <h3 class="font-thin font-roboto text-uf-blue text-xl uppercase">Katja Brüser</h3>

                            <p class="font-semibold font-roboto text-uf-blue text-lg ">Pixelschubserin</p>

                            <p class="font-thin font-roboto text-white text-lg">Macht die digitale Welt mit ihren Designs seit 10 Jahren schöner. Und das sogar funktional.</p>
                        </div>
                    </div>
                </li>
            </ul>
        </div>
    </section>
</template>

<script>
export default {
    name: 'Team'
}
</script>
