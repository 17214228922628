<template>
    <footer class="bg-uf-blue">
        <div class="max-w-7xl mx-auto py-12 px-4 sm:px-6 lg:py-16 lg:px-8">
            <div class="xl:grid xl:grid-cols-2 xl:gap-8">
                <div class="space-y-8 grid-cols-1">
                    <img class="h-8 w-auto sm:h-10" src="@/assets/images/logo_white.svg" alt="UnitFactory">

                    <p class="text-white text-lg uppercase font-light font-roboto">
                        Meld dich doch mal!
                    </p>

                    <p class="text-white text-lg font-light font-roboto">
                        Zusammen bauen wir deine Company.
                    </p>
                </div>

                <div class="grid grid-cols-2 gap-8">
                    <div class="md:grid md:grid-cols-1 md:gap-8">
                        <ul class="mt-4 space-y-4">
                            <li>
                                <a href="#about"
                                   class="font-roboto text-lg uppercase text-white"
                                   v-smooth-scroll
                                >
                                    <span>Über uns</span>
                                </a>
                            </li>

                            <li>
                                <a href="#team"
                                   class="font-roboto text-lg uppercase text-white"
                                   v-smooth-scroll
                                >
                                    <span>Team</span>
                                </a>
                            </li>

                            <li>
                                <a href="#projects"
                                   class="font-roboto text-lg uppercase text-white"
                                   v-smooth-scroll
                                >
                                    <span>Projekte</span>
                                </a>
                            </li>

                            <li>
                                <a href="mailto:hallo@unitfactory.de"
                                   class="font-roboto text-lg uppercase text-white"
                                   v-smooth-scroll
                                >
                                    <span>Kontakt</span>
                                </a>
                            </li>
                        </ul>
                    </div>

                    <div class="grid grid-cols-1 gap-8">
                        <div class="md:grid md:grid-cols-1 md:gap-8">
                            <ul class="mt-4 space-y-4">
                                <li>
                                    <a href="/impressum"
                                       class="font-roboto text-lg uppercase text-white"
                                    >
                                        <span>Impressum</span>
                                    </a>
                                </li>

                                <li>
                                    <a href="/datenschutz"
                                       class="font-roboto text-lg uppercase text-white"
                                    >
                                        <span>Datenschutz</span>
                                    </a>
                                </li>
                            </ul>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </footer>
</template>

<script>
export default {
    name: 'Footer'
}
</script>
