<template>
    <div id="app">
        <Headline></Headline>

        <Navbar></Navbar>

        <router-view></router-view>

        <Footer></Footer>
    </div>
</template>

<script>
import Headline from '@/components/Headline';
import Navbar from '@/components/Navbar';
import Footer from "@/pages/sections/Footer";

export default {
    name: 'App',
    components: {
        Headline,
        Navbar,
        Footer
    }
}
</script>
