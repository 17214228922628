import Vue from 'vue';
import VueRouter from 'vue-router';

Vue.use(VueRouter);

import Home from '@/pages/Home';
import Imprint from '@/pages/Imprint';
import Privacy from "@/pages/Privacy";

const routes = [
    {
        path: '/',
        component: Home
    },
    {
        path: '/impressum',
        component: Imprint
    },
    {
        path: '/datenschutz',
        component: Privacy
    }
];

export const router = new VueRouter({
    mode: 'history',
    routes
});
