<template>
    <div>
        <section class="h-screen bg-uf-turquoise font-roboto uppercase text-white text-4xl flex items-center justify-center flex-col">
            <p class="font-thin">Willkommen</p>
            <p class="font-semibold">bei UnitFactory</p>
        </section>

        <About></About>

        <Team></Team>

        <Projects></Projects>
    </div>
</template>

<script>
import About from '@/pages/sections/About';
import Team from '@/pages/sections/Team';
import Projects from '@/pages/sections/Projects';

export default {
    name: 'Home',
    components: {
        About,
        Team,
        Projects,
    }
}
</script>