<template>
    <section id="about" class="h-screen pt-32">
        <div class="container flex items-center flex-col">
            <div class="font-roboto uppercase text-uf-blue text-4xl text-center">
                <h1 class="font-thin">Wir sind die Zahnräder</h1>
                <p class="font-semibold">für deine Idee</p>
            </div>

            <p class="font-roboto text-uf-blue text-lg px-6 md:w-1/2 pt-20 text-center">
                UnitFactory unterstützt junge Unternehmer und Unternehmerinnen dabei, Ihre Ideen im digitalen Universum umzusetzen. Wir verstehen uns dabei als Company Builder und Accelerator und beraten bei der Entwicklung vom kleinsten Zahnrad bis hin zur fertigen Maschine. Unsere Teams aus Entwicklern, Grafikern, BWL-, Marketing- und Vertriebsprofis liefern dir dabei die passenden Resourcen, um das Beste aus deiner Idee herauszuholen.
            </p>
        </div>
    </section>
</template>

<script>
export default {
    name: 'About'
}
</script>
