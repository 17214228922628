import Vue from 'vue';
import App from './App.vue';
import { router } from "@/routes";
import vueSmoothScroll from 'vue2-smooth-scroll'
import './assets/css/app.css';

Vue.config.productionTip = false;
Vue.use(vueSmoothScroll);

new Vue({
  router,
  render: h => h(App),
}).$mount('#app')
